import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

import "./Article.css";
import bible from "./media/bible.svg";
import SubsiteTemplate from "./SubsiteTemplate";
import { Button } from "react-bootstrap";

function Article() {
  // Get id from URI
  const { id } = useParams();
  // Stete to store article
  const [article, setArticle] = useState([]);
  const alt = "Skrypt";

  // Fetch article from API
  useEffect(() => {
    fetch(`http://api.parafiawiekszyce.pl/articles/get/id/${id}`)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        }
        return {
          id: 0,
          date: moment().format("YYYY-MM-DD"),
          title: "Błąd",
          image: "no_image.png",
          content: "Nie można załadować artykułu.",
        };
      })
      .then(json => {
        setArticle(json);
      });
  }, [id]);

  // Add "Go back" button to header
  const title = (
    <div className="title-container">
      {article.title}
      <Button variant="outline-primary" href="/aktualnosci" className="py-1">
        Wróć
      </Button>
    </div>
  );

  // Article content
  const content = (
    <div id="aricle">
      <img
        src={importImage(article.image)}
        onClick={() => showImage(article.title)}
        alt="Miniaturka"
        className="article-image"
      />
      {/* The Modal */}
      <div id="modal" className="modal">
        {/* The Close Button */}
        <span onClick={closeImage} className="close">
          &times;
        </span>
        {/* Modal Content (The Image) */}
        <img
          className="modal-content"
          id="modal-image"
          src={importImage(article.image)}
          alt="Powiększony obraz"
        />
        {/* Modal Caption (Image Text) */}
        <div id="caption"></div>
      </div>
      <p dangerouslySetInnerHTML={{ __html: article.content }} />
    </div>
  );

  return (
    <SubsiteTemplate icon={bible} alt={alt} title={title} content={content} />
  );
}

function importImage(path) {
  if (typeof path === "undefined" || path === "no_image.png") {
    return "";
  }
  return `http://media.parafiawiekszyce.pl/${path}`;
}

function showImage(altText) {
  const modal = document.getElementById("modal"); // Get modal
  const captionText = document.getElementById("caption");
  modal.style.display = "block";
  captionText.innerHTML = altText;
}

function closeImage() {
  let modal = document.getElementById("modal");
  modal.style.display = "none";
}

export default Article;
