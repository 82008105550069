import SubsiteTemplate from "./SubsiteTemplate";
import gospel from "./media/gospel.svg"

function Gallery() {
    const alt = "Śpiewnik";
    const title = "Galeria";
    const content = "Już wkrótce!";

    return (
        <SubsiteTemplate icon={gospel} alt={alt} title={title} content={content} />
    );
}

export default Gallery;