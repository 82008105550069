import { Card, Container } from "react-bootstrap";

import './SubsiteTemplate.css';

function SubsiteTemplate(props) {
    return (
        <Container fluid className="h-100"> 
            <Card className="h-100">
                <Card.Header>
                    <Card.Title className="heading">
                        <img src={props.icon} height={32} width={32} alt={props.alt} className="icon" />
                        {props.title}
                    </Card.Title>
                </Card.Header>
                <Card.Body className="card-body-scrollable">
                    {renderHtml(props.content)}
                </Card.Body>
            </Card>
        </Container>
    );
}

function renderHtml(input) {
    if (typeof input == "string") {
        return <div dangerouslySetInnerHTML={{__html: input}} />;
    }
    return input;
}

export default SubsiteTemplate;