import { Row, Col } from "react-bootstrap";

import SubsiteTemplate from "./SubsiteTemplate";
import priest from "./media/priest.svg";

function Contact() {
    const alt = "Ksiądz";
    const title = "Kontakt";
    const content = (
        <div>
        <Row xs={1} md={2} lg={3}>
            <Col className="mb-5">
                <h5>Kancelaria parafialna</h5>
                Czynna od poniedziałku do piątku<br/>
                W godzinach od 9:00 do 12:00<br/>
                Nr konta parafialnego:<br/> 62 8882 1032 2001 0029 7518 0001
            </Col>
            <Col className="mb-5">
                <h5>Kontakt</h5>
                Ks. Piotr Adamów<br/>
                tel.: <a href="tel:+48729874214">+48 729 874 214</a><br/>
                email: <a href="mailto:adres@email.com">adres@email.com</a>
            </Col>
            <Col className="mb-5">
                <h5>Adres</h5>
                ul. Hahna 1<br/>
                47-208 Większyce
            </Col>
        </Row>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2546.503696963659!2d18.100602415251412!3d50.33850997946002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47110ddc556a25ff%3A0x1fa9c1cf2bf5d242!2sParafia%20pw.%20%C5%9Bw.%20Urbana%20w%20Wi%C4%99kszycach!5e0!3m2!1spl!2spl!4v1612904880010!5m2!1spl!2spl" height="450" style={{border:0, width: "100%"}} aria-hidden="false" title="Google-maps"></iframe>
        </div>
    );

    return <SubsiteTemplate icon={priest} alt={alt} title={title} content={content} />;
}

export default Contact;