import { useEffect, useState } from "react";
import SubsiteTemplate from "./SubsiteTemplate";

import "./News.css";
import bible from "./media/bible.svg";
import { Card, Col, Row } from "react-bootstrap";

function News() {
  // Store articles in state
  const [articles, setArticles] = useState([]);
  const alt = "Biblia";
  const title = "Aktualności";
  // Fetch articles from API
  useEffect(() => {
    fetch("http://api.parafiawiekszyce.pl/articles/get")
      .then(response => {
        if (response.status === 200) {
          return response.json();
        }
        return [];
      })
      .then(json => setArticles(json));
  }, []);

  // Subsite content
  const content = (
    <Row xs={1} md={2} lg={3}>
      {articles.map(article => (
        <Col key={article.id}>
          <a href={`/artykul/${article.id}`} className="link-nostyle">
            <Card key={article.id} className="text-center mb-5 article-block">
              <Card.Img
                variant="top"
                src={`http://media.parafiawiekszyce.pl/${article.image}`}
                className="thumbnail pt-3"
              />
              <Card.Body>
                <Card.Title>{article.title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  {article.date.split(" ")[0]}
                </Card.Subtitle>
              </Card.Body>
            </Card>
          </a>
        </Col>
      ))}
    </Row>
  );

  return (
    <SubsiteTemplate icon={bible} alt={alt} title={title} content={content} />
  );
}

export default News;
