import './Home.css';

function Home() {
    return (
        <div className="home">

        </div>
    );
}

export default Home;