import SubsiteTemplate from "./SubsiteTemplate";
import communion from "./media/communion.svg";

function Events() {
    const alt = "Komunia";
    const title = "Msze św.";
    const content = (
        <div>
            <h5>Od poniedziałku do piątku:</h5>
            <ul>
                <li>18:00</li>
            </ul>
            <h5>Sobota:</h5>
            <ul>
                <li>8:00</li>
                <li>18:00 - msza św. niedzielna</li>
            </ul>
            <h5>Niedziela:</h5>
            <ul>
                <li>8:00</li>
                <li>10:30</li>
                <li>15:00</li>
            </ul>
        </div>
    );

    return (
        <SubsiteTemplate icon={communion} alt={alt} title={title} content={content} />
    );
}

export default Events;