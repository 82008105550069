import React, { useEffect, useState } from "react";
import SubsiteTemplate from "./SubsiteTemplate";

import commandments from "./media/ten-commandments.svg";
import moment from "moment";
import { Form } from "react-bootstrap";

function Announcments() {
  // Store selected week in state
  const [week, setWeek] = useState(
    `${moment().format("YYYY")}-W${moment().format("WW")}`
  );
  // Store announcments in state
  const [announcments, setAnnouncments] = useState([]);
  const alt = "Tablice";
  const title = "Ogłoszenia";
  // Fetch announcments from API based on selected week
  useEffect(() => {
    fetch(`http://api.parafiawiekszyce.pl/announcmentstext/get/week/${week}`)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        return [
          {
            id: 0,
            date: "",
            name: "Brak danych",
            week: moment().format("WW"),
          },
        ];
      })
      .then((json) => setAnnouncments(json));
  }, [week]);
  // Subsite content
  const content = (
    <div>
      <h5>Tydzień {moment().format("W")}</h5>
      {/* Form to select week number */}
      <Form className="mb-4">
        <input
          type="week"
          className="week"
          value={week}
          onChange={(event) => {
            setWeek(event.target.value);
          }}
        />
      </Form>
      {/* List of announcments */}
      <ul>
        <div dangerouslySetInnerHTML={{ __html: announcments.content }} />
      </ul>
    </div>
  );
  return (
    <SubsiteTemplate
      icon={commandments}
      alt={alt}
      title={title}
      content={content}
    />
  );
}

export default Announcments;
