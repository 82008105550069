import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import { Nav, Navbar } from "react-bootstrap";

import church from "./media/church.svg"
import bible from "./media/bible.svg"
import commandments from "./media/ten-commandments.svg"
import communion from "./media/communion.svg"
import gospel from './media/gospel.svg'
import priest from "./media/priest.svg"
import jesus from './media/jesus.svg'
import './App.css';
import Home from "./Home";
import News from "./News";
import Announcments from './Announcments';
import Events from './Events';
import Gallery from './Gallery';
import Contact from './Contact';
import Article from './Article';

const toggleSidebar = () => {
  const burger = document.getElementsByClassName("burger")[0];
  const sidebar = document.getElementsByClassName("sidebar")[0];
  const content = document.getElementsByClassName("content")[0];

  burger.classList.toggle("burger-toggle");
  sidebar.classList.toggle("sidebar-toggle");
  content.classList.toggle("content-shadow");
}

function App() {
  return (
    <div className="App">
      <Router>
        <div className="sidebar">
          <ul className="sidebar-menu">
            <Link to="/" className="link-nostyle" onClick={toggleSidebar}><li className="sidebar-link">
              <img src={church} height={32} width={32} alt="Kościół" />
              Strona główna
            </li></Link>
            <Link to="/aktualnosci" className="link-nostyle" onClick={toggleSidebar}><li className="sidebar-link">
              <img src={bible} height={32} width={32} alt="Biblia" />
              Aktualności
            </li></Link>
            <Link to="/ogloszenia" className="link-nostyle" onClick={toggleSidebar}><li className="sidebar-link">
              <img src={commandments} height={32} width={32} alt="Tablice" />
              Ogłoszenia
            </li></Link>
            <Link to="/msze" className="link-nostyle" onClick={toggleSidebar}><li className="sidebar-link">
              <img src={communion} height={32} width={32} alt="Komunia" />
              Msze św.
            </li></Link>
            <Link to="/galeria" className="link-nostyle" onClick={toggleSidebar}><li className="sidebar-link">
              <img src={gospel} height={32} width={32} alt="Śpiewnik" />
              Galeria
            </li></Link>
            <Link to="/kontakt" className="link-nostyle" onClick={toggleSidebar}><li className="sidebar-link">
              <img src={priest} height={32} width={32} alt="Ksiądz" />
              Kontakt
            </li></Link>
          </ul>
        </div>
        <Navbar variant="dark" fixed="top" className="header">
          <Nav className="mr-auto">
            <Navbar.Brand href="/" className="logo">
              <img src={jesus} height={32} width={32} alt="Jezus" />
              Parafia Większyce
            </Navbar.Brand>
          </Nav>
          <Nav className="ml-auto">
            <button onClick={toggleSidebar} className="sidebar-button">
              Menu
              <div className="burger">
                <div className="line1"></div>
                <div className="line2"></div>
                <div className="line3"></div>
              </div>
            </button>
          </Nav>
        </Navbar>
        <div className="content">
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/aktualnosci" component={News} />
              <Route path="/ogloszenia" component={Announcments} />
              <Route path="/msze" component={Events} />
              <Route path="/galeria" component={Gallery} />
              <Route path="/kontakt" component={Contact} />
              <Route path="/artykul/:id" component={Article} />
            </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
